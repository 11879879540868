import React, { useEffect, useState } from 'react';
import { PlayerWithoutHiscores } from '../../types/types';
import { fetchPlayers } from '../../services/api';
import { Link } from '@mui/material';
import { Loading } from '../../components/Loading';

const PlayersPage: React.FC = () => {
  const [players, setPlayers] = useState<PlayerWithoutHiscores[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const activePlayers = players.filter((player) => !player.paused);
  const pausedPlayers = players.filter((player) => player.paused);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await fetchPlayers();
      setPlayers(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="playersPage">
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="headerContainer">
            <h1 className="smallTitle">Players</h1>
            <p>
              <span className="block">
                Inactive players are paused after two weeks.
              </span>
              <span className="block">
                Resume tracking from the player profile page.
              </span>
            </p>
          </div>

          <div className="containersWrapper">
            <div className="container">
              <h2 className="smallTitle">{`Tracked players (${activePlayers.length})`}</h2>
              <ul className="list">
                {activePlayers.map((player) => (
                  <li key={player.id}>
                    <Link href={`/players/${player.id}`} className="link">
                      {player.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="container">
              <h2 className="smallTitle">{`Paused players (${pausedPlayers.length})`}</h2>
              <ul className="list">
                {pausedPlayers.map((player) => (
                  <li key={player.id}>
                    <Link href={`/players/${player.id}`} className="link">
                      {player.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PlayersPage;
