export interface Player {
  id: number;
  name: string;
  paused: boolean;
  created_at: Date;
  hiscores: Hiscore[];
}

export interface PlayerWithoutHiscores {
  id: number;
  name: string;
  paused: boolean;
  created_at: Date;
}

export interface Leaderboard {
  overall: LeaderboardData[];
  fighting: LeaderboardData[];
  ranged: LeaderboardData[];
  prayer: LeaderboardData[];
  magic: LeaderboardData[];
  cooking: LeaderboardData[];
  woodcutting: LeaderboardData[];
  fletching: LeaderboardData[];
  fishing: LeaderboardData[];
  firemaking: LeaderboardData[];
  crafting: LeaderboardData[];
  smithing: LeaderboardData[];
  mining: LeaderboardData[];
  herblaw: LeaderboardData[];
  agility: LeaderboardData[];
  thieving: LeaderboardData[];
}

export interface LeaderboardData {
  player_name: string;
  player_id: number;
  exp_gain: number;
  ranks_gain: number;
  levels_gain: number;
  start_date: Date;
  end_date: Date;
}

export interface Hiscore {
  id: number;
  created_at: Date;
  player_id: number;
  ranks: HiscoreData[];
  experiences: HiscoreData[];
  levels: HiscoreData[];
}

export interface HiscoreBase {
  overall: number;
  fighting: number;
  ranged: number;
  prayer: number;
  magic: number;
  cooking: number;
  woodcutting: number;
  fletching: number;
  fishing: number;
  firemaking: number;
  crafting: number;
  smithing: number;
  mining: number;
  herblaw: number;
  agility: number;
  thieving: number;
}

export interface PlayerData {
  player1: ComparePlayersData;
  player2: ComparePlayersData;
}

export interface ComparePlayersData {
  name: string;
  ranks: Record<string, number>;
  levels: Record<string, number>;
  experiences: Record<string, number>;
}

export interface HiscoreData extends HiscoreBase {
  id: number;
  created_at: Date;
  hiscore_id: number;
}

export type TimePeriod = 'all' | 'day' | 'week' | 'month' | 'year';

export type LeaderboardTab = 'currentTop' | 'records';

export type DataFormat = 'csv' | 'xlsx';

export const skillFields: (
  | 'overall'
  | 'fighting'
  | 'ranged'
  | 'prayer'
  | 'magic'
  | 'cooking'
  | 'woodcutting'
  | 'fletching'
  | 'fishing'
  | 'firemaking'
  | 'crafting'
  | 'smithing'
  | 'mining'
  | 'herblaw'
  | 'agility'
  | 'thieving'
)[] = [
  'overall',
  'fighting',
  'ranged',
  'prayer',
  'magic',
  'cooking',
  'woodcutting',
  'fletching',
  'fishing',
  'firemaking',
  'crafting',
  'smithing',
  'mining',
  'herblaw',
  'agility',
  'thieving',
];

export const ErrorMessage = {
  ALREADY_REGISTERED: (playerName: string) =>
    `Player name ${playerName} already registered`,
  NOT_FOUND_ON_SERVER: (playerName: string) =>
    `Player name ${playerName} not found on rsc.vet preservation server`,
  MINIMUM_TOTAL_LEVEL: () =>
    'You can only add a player if you have at least 150 total level',
  EMPTY_NAME: () => 'Player name can not be empty',
  MAX_LENGTH_EXCEEDED: () => 'Max length of a player name is 12',
  GENERIC_ERROR: (playerName: string) =>
    `Failed to create player ${playerName}`,
};
